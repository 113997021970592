.spinner {
    display: inline-block;
    pointer-events: none;
}

.spinner:after {
    pointer-events: none;
    content: ' ';
    display: block;
    width: 10rem;
    height: 10rem;
    margin: 1rem;
    border-radius: 50%;
    border: 0.4rem solid hsl(180, 100%, 40%);
    border-color: hsl(180, 100%, 40%) transparent hsl(180, 100%, 40%) transparent;
    animation: spinner 2.4s ease-in-out infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}